/**
 * Graphical Mode プロセスごと、Transportationごとに設定する線の定義
 * 輸送手段での出しわけにより、補完する必要がある線が出てきたため辞書化し定義しておく
 * ※ 必要に応じて随時追加する必要があり
 */
import { PROCESS_TYPE, TRANSPORTATION } from 'lib-tw-common';

const customEdges = {
  relationMap: {
    // Relation Map プロセスごとの設定 -------------------------------------------------------------
    // Booking依頼
    [PROCESS_TYPE.TPATPRB]: {
      addEdges: [
        { "from": "shipperGrp", "to": "surveyorGrpEx", "color": { "highlight": "#9FC1E9" } },
      ],
    },
    // B/L発行依頼
    [PROCESS_TYPE.TPRBL]: {
      addEdges: [
        { "from": "shipperGrp", "to": "surveyorGrpEx", "color": { "highlight": "#9FC1E9" } },
      ],
    },
    // B/L情報
    [PROCESS_TYPE.TPBLI]: {
      addEdges: [
        { "from": "shipperGrp", "to": "surveyorGrpEx", "color": { "highlight": "#9FC1E9" } },
      ],
    },
    // C/O申請
    [PROCESS_TYPE.FDCOR]: {
      addEdges: [
        { "from": "shipperGrp", "to": "surveyorGrpEx", "color": { "highlight": "#9FC1E9" } },
      ],
    },
    // C/O発行
    [PROCESS_TYPE.FDCOI]: {
      addEdges: [
        { "from": "shipperGrp", "to": "surveyorGrpEx", "color": { "highlight": "#9FC1E9" } },
      ],
    },
    // Relation Map 輸送手段別の設定 ---------------------------------------------------------------
    // Air
    [TRANSPORTATION.AIRCRAFT]: {
      addEdges: [
        { "from": "shipperGrp", "to": "airfreightGrp", "color": { "highlight": "#5185C5" } },
        { "from": "shipperGrp", "to": "carrierGrp", "color": { "highlight": "#5185C5" } },
        { "from": "consigneeGrp", "to": "impForwarderNvoccGrp", "color": { "highlight": "#5185C5" } },
      ],
      // 更にプロセスごとの設定が必要な場合
      [PROCESS_TYPE.ICREQ]: {
        addEdges: [
          { "from": "shipperGrp", "to": "forwarderNvoccGrp", "color": { "highlight": "#5185C5" } },
        ],
      },
      [PROCESS_TYPE.ICPERMIT]: {
        addEdges: [
          { "from": "shipperGrp", "to": "forwarderNvoccGrp", "color": { "highlight": "#5185C5" } },
        ],
      },
      [PROCESS_TYPE.TPATPRB]: {
        addEdges: [
          { "from": "shipperGrp", "to": "forwarderNvoccGrp", "color": { "highlight": "#5185C5" } },
        ],
      },
      [PROCESS_TYPE.TPRBL]: {
        addEdges: [
          { "from": "shipperGrp", "to": "forwarderNvoccGrp", "color": { "highlight": "#5185C5" } },
        ],
      },
      [PROCESS_TYPE.TPBLI]: {
        addEdges: [
          { "from": "shipperGrp", "to": "forwarderNvoccGrp", "color": { "highlight": "#5185C5" } },
        ],
      },
      [PROCESS_TYPE.FDCOR]: {
        addEdges: [
          { "from": "shipperGrp", "to": "forwarderNvoccGrp", "color": { "highlight": "#5185C5" } },
        ],
      },
      [PROCESS_TYPE.FDCOI]: {
        addEdges: [
          { "from": "shipperGrp", "to": "forwarderNvoccGrp", "color": { "highlight": "#5185C5" } },
        ],
      },
    },
    // Container
    [TRANSPORTATION['VESSEL(CONTAINER)']]: {
      addEdges: [],
      [PROCESS_TYPE.ICREQ]: {
        addEdges: [
          { "from": "shipperGrp", "to": "airfreightGrp", "color": { "highlight": "#5185C5" } },
          { "from": "shipperGrp", "to": "carrierGrp", "color": { "highlight": "#5185C5" } },
          { "from": "shipperGrp", "to": "forwarderNvoccGrp", "color": { "highlight": "#5185C5" } },
        ],
      },
    },
    // Complex
    [TRANSPORTATION.COMPLEX]: {
      addEdges: [],
      [PROCESS_TYPE.ICREQ]: {
        addEdges: [
          { "from": "shipperGrp", "to": "airfreightGrp", "color": { "highlight": "#5185C5" } },
          { "from": "shipperGrp", "to": "carrierGrp", "color": { "highlight": "#5185C5" } },
          { "from": "shipperGrp", "to": "forwarderNvoccGrp", "color": { "highlight": "#5185C5" } },
        ],
      },
    },
  },
  // Time Line プロセスごとの設定
  timeLine: {
    [PROCESS_TYPE.ECREQ]: {
      // B/L情報群がないプロセスでの保管
      addEdges: [
        { "from": "logisticsLoadingGrp", "to": "airGrp", "color": { "highlight": "#C6EADA" } },
        { "from": "logisticsLoadingGrp", "to": "containerGrp", "color": { "highlight": "#C6EADA" } },
        { "from": "logisticsLoadingGrp", "to": "bulkGrp", "color": { "highlight": "#C6EADA" } },
      ],
    },
  }
}

export default customEdges;
