<template>
  <div class="graphical_network">
    <!-- タブ, クローズボタン -->
    <div class="tabs">
      <div class="tab" :class="{ active: activeTab === 'tab1' }" @click="tabClick('tab1')">
        <p>Relation Map</p>
      </div>
      <div class="tab" :class="{ active: activeTab === 'tab2' }" @click="tabClick('tab2')">
        <p>Time Line</p>
      </div>
      <button class="close_button" @click="closeGraphical"><img src="@/assets/images/icons/icon_close.svg"></button>
    </div>
    <div class="content">
      <!-- Relation Map -->
      <TwGraphicalNetworkItem
        :isShow="activeTab === 'tab1'"
        :nodes="relationMapNodes.nodes"
        :edges="relationMapEdges.edges"
        :tables="schemas.tables"
        @show-detail="showDetail"
      />
      <!-- Time Line -->
      <TwGraphicalNetworkItem
        :isShow="activeTab === 'tab2'"
        :nodes="timeLineNodes.nodes"
        :edges="timeLineEdges.edges"
        :tables="schemas.tables"
        @show-detail="showDetail"
        :timeLine="true"
      />
      <!-- サイドバー -->
      <transition name="down">
        <div class="side_bar" v-show="isShowDetail" ref="side_bar">
          <tw-process-group-list
            v-if="Object.keys(detail).length"
            :tableName="detail.tableName"
            :group="detail.group"
            :key="detail.group.variableName"
            :isGraphicalMode="true"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
// constant
// import { PROCESS_TYPE, TRANSPORTATION } from 'lib-tw-common';
// components
import TwGraphicalNetworkItem from '@/components/molecules/TwGraphicalNetworkItem';
import TwProcessGroupList from '@/components/molecules/TwProcessGroupList';
// dictionaries
import relationMapNodes from '@/dictionaries/graphical/relationMap/nodes.json'
import relationMapEdges from '@/dictionaries/graphical/relationMap/edges.json'
import timeLineNodes from '@/dictionaries/graphical/timeLine/nodes.json'
import timeLineEdges from '@/dictionaries/graphical/timeLine/edges.json'
import customEdges from '@/dictionaries/graphical/customEdges'

export default {
  name: 'TwGraphicalNetwork',
  inject: ['s'],
  provide() {
    return {
      checkTypeOfWorks: this.s.checkTypeOfWorks,
      checkTransportation: this.s.checkTransportation,
      checkPaymentTerms: this.s.checkPaymentTerms,
    }
  },
  props: {
    schemas: Object,
  },
  components: {
    TwGraphicalNetworkItem,
    TwProcessGroupList,
  },
  data() {
    return {
      activeTab: 'tab1',
      detail: {}, // サイドバーに表示する情報を格納
      // json
      relationMapNodes: _.cloneDeep(relationMapNodes),
      relationMapEdges: _.cloneDeep(relationMapEdges),
      timeLineNodes: _.cloneDeep(timeLineNodes),
      timeLineEdges: _.cloneDeep(timeLineEdges),
      isShowDetail: false,
    }
  },
  computed: {},
  created() {
    window.addEventListener('keydown', this.onKeyDown, false);
    const transportation = _.get(this.s, 'res.linkageTransportation.transferTermsGrp.transportation')
    if (transportation || transportation === 0) {
      // 輸送手段ごとの線の補完
      const customEdge = _.get(customEdges, `relationMap[${transportation}]`)
      if (customEdge) {
        if (customEdge.addEdges.length) {
          this.relationMapEdges.edges = this.relationMapEdges.edges.concat(customEdge.addEdges)
        }
        // 更にプロセスごとに補完する必要がある場合
        const processEdge = _.get(customEdge, `${this.s.processType}`)
        if (processEdge && processEdge.addEdges.length) {
          this.relationMapEdges.edges = this.relationMapEdges.edges.concat(processEdge.addEdges)
        }
      }
    }
    // Relation Map プロセスごとの線の補完
    const relationMapProcessEdge = _.get(customEdges, `relationMap[${this.s.processType}]`)
    if (relationMapProcessEdge) {
      const addEdges = relationMapProcessEdge.addEdges
      if (addEdges.length) {
        this.relationMapEdges.edges = this.relationMapEdges.edges.concat(addEdges)
      }
    }
    // Time Line プロセスごとの線の補完
    const timeLineProcessEdge = _.get(customEdges, `timeLine[${this.s.processType}]`)
    if (timeLineProcessEdge) {
      const addEdges = timeLineProcessEdge.addEdges
      if (addEdges.length) {
        this.timeLineEdges.edges = this.timeLineEdges.edges.concat(addEdges)
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown, false);
  },
  methods: {
    // canvasをクリックした際の処理 サイドバーに情報を表示します
    showDetail(tableName, group) {
      if (tableName && group) {
        this.detail = { tableName: tableName, group: group }
        this.isShowDetail = true
        const side_bar = _.get(this, '$refs.side_bar')
        if (side_bar.scrollTop) side_bar.scrollTop = 0
        // console.log(this.detail.tableName, this.detail.group)
      } else {
        this.isShowDetail = false
        setTimeout(() => {
          this.detail = {}
        }, 300);
      }
    },
    // タブ切り替え
    tabClick(tab) {
      if (this.activeTab === tab) return
      this.activeTab = tab
      this.isShowDetail = false
      setTimeout(() => {
        this.detail = {}
      }, 300);
    },
    // 閉じる
    closeGraphical() {
      this.$emit('show-graphical')
    },
    onKeyDown(event) {
      if (event.key === "Tab") {
        // デフォルト動作停止
        event.preventDefault();
      }
    }
  }
}
</script>

<style lang="scss">
.graphical_network {
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;;
  background: $color_gray_300;
  padding: 33px 32px 0;
  overflow-x: scroll;
  .tabs {
  display: flex;
  position: relative;
  .tab {
    height: 40px;
    padding: 10px 24px;
    font-size: 14px;
    background: $color_gray_100;
    color: $color_gray_600;
    border-radius: 6px 6px 0px 0px;
    margin-right: 2px;
    cursor: pointer;
    font-weight: bold;
    box-shadow: none;
    > p {
      margin: 0;
    }
    &.active {
      background: $color_white;
      color: $color_dark_blue;
    }
  }
  .close_button {
    position: absolute;
    top: -10px;
    right: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: none;
  }
  }
  div.content {
    height: calc(100% - 74px);
    position: relative;
  }
  .side_bar {
    position: absolute;
    top: 0;
    right: 0;
    width: 263px;
    // height: calc(100% + 34px);
    max-height: calc(100% - 64px);
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(170, 170, 204, 0.5);
    padding: 16px 20px;
    overflow-y: scroll;
    margin: 36px;
    background: rgba(255,255,255,0.8);
    table {
      width: 100%;
    }
    .tw_process_group_list {
      .inner {
        padding-top: 4px;
        cursor: auto;
        .group_name {
          font-size: 16px;
          font-weight: bold;
          margin-left: 0;
        }
      }
      .collapse {
        >div {
          padding-left: 0 !important;
        }
        .tw_process_group_list_item {
          display: block;
          margin-bottom: 16px;
          .label {
            font-size: 14px;
            font-weight: bold;
            color: $color_black;
            width: 100%;
          }
          .value {
            font-size: 12px;
            width: 100%;
          }
        }
        .column {
          display: block;
        }
        .twoColumn {
          display: block;
          padding: 0;
          .tw_process_group_list_item_wrapper {
            min-width: auto;
            width: 100%;
          }
          .tw_process_group_list_item {
            min-width: auto;
            width: 100%;
            padding-right: 0;
          }
        }
      }
    }
  }

  .network_container {
    width: 100%;
    height: 100%;
  }
  #network {
    z-index: 2;
    width:  100%;
    height: 100%;
    background: #FFFFFF;
    border: 4px solid #F9F9FD;
    box-shadow: 0px 4px 8px rgba(170, 170, 204, 0.5);
    border-radius: 0px 6px 6px 6px;
    // padding-right: 259px;
    canvas {
      background: url(../../assets/images/map.svg) no-repeat top center;
      background-size: cover;
    }
  }
  div.vis-tooltip {
    max-width: 250px;
    font-weight: bold !important;
    font-size: 12px !important;
    color: $color_white;
    line-height: 13.62px !important;
    background: $color_gray_800 !important;
    padding: 4px 8px !important;
    border-radius: 12px !important;
    white-space: normal!important;
  }

 .down-enter-active, .down-leave-active {
    transition: 0.3s;
    max-height: calc(100% - 64px);
  }
  .down-enter, .down-leave-to {
    max-height: 0;
  }
}
</style>
