<template>
  <div v-if="isShow" class="network_container">
    <Network
      id="network"
      ref="network"
      :nodes="dataSet"
      :edges="edges"
      :options="options"
      @click="click($event)"
    />
    <!-- 開発用: nodeの座標をエクスポートします（エクスポート手順は一番下の方に記載） -->
    <div v-if="exportMode" style="margin-top: 40px">
      <textarea id="input_output" ref="exportArea" style="min-width: 400px;min-height: 400px"></textarea>
      <input type="button" id="export_button" @click="exportNetwork()" value="export" ref="exportButton" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { Network } from 'vue-visjs' // Vis.js
import { drawPolygon, drawArc } from '@/utils/ctxDraw' // ctxRenderer図形描画関数
import { TYPE_OF_SERVICE_FCL_LCL_VARIABLES, TYPE_OF_SERVICE_VARIABLES } from 'lib-tw-common';
// import dayjs from 'dayjs';
// import { formatUtcOffsetDate } from '@/utils/searchUtil.js';
// 出し分け用辞書
import transportationControl from '@/dictionaries/transportationControl.json';
import paymentControlGraphicalRelationMap from '@/dictionaries/paymentControlGraphicalRelationMap.json';

export default {
  name: 'TwGraphicalNetworkItem',
  inject: ['s', 'checkTypeOfWorks'],
  props: {
    isShow: Boolean,
    nodes: Array,
    edges: Array,
    tables: Array, // schemas.tables
    timeLine: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Network,
  },
  data() {
    return {
      exportMode: false, // 開発用: 座標位置の調整をするときのみtrueにします
      nameLength: 10, // 図形の下に表示するNameの最大文字数 超過したら...表示
      dataSet: [],
      options: {
        physics: {
          enabled: false, // trueにするとフワフワ動く
        },
        interaction:{
          hover: true,
        },
        nodes: {
          size: 20,
          mass: 4, // nodeの重さ、physics.enabled: falseの場合は意味ない
          margin: 100,
          fixed: true, // nodeの固定
          shape: 'custom' // ctxRenderer描画する場合は'custom'で固定
        },
        edges: {
          width: 1,
          smooth: false, // 曲線にするかどうか
          color: '#BFBFCD',
          hoverWidth: 1,
          selectionWidth: 1.5,
        },
      },
      // focusOption: {
      //   scale: 1.05,
      //   locked: false,
      //   animation: {
      //     duration: 300,
      //     easingFunction: 'linear'
      //   }
      // },
    }
  },
  computed: {},
  created() {
    this.dataSet = this.initDataSet()
  },
  methods: {
    /**
    * 初期化処理
    * フィルタリングしたjsonデータをnetworkコンポーネントに渡すデータセットに変換します
    */
    initDataSet() {
      let filtered = [] // フィルタリングしたnodeを格納する配列
      // フィルタリングしたnodeを格納する処理
      _.forEach(this.nodes, item => {
        if (this.exportMode) {
          item.fixed = false
          filtered.push(item)
        } else {
          const table = _.find(this.tables, { variableName: item.tableName })
          if (!table) return
          // NOTE: 各node(item)のidには、グループ名を指定しているが、'customsAgentGrp', 'surveyorGrp'は、複数存在するため末尾2文字に固有のidを付与して、nodes.jsonに設定している
          //       そのため、検索に用いる際には、'customsAgentGrp', 'surveyorGrp'は、末尾2文字を除外して、テーブル名 + グループ名で検索を行う
          const groupName = item.id.includes('customsAgentGrp') || item.id.includes('surveyorGrp') ? item.id.slice(0, -2) : item.id
          // 輸送手段による出しわけ
          if (!this.checkTransportation(item.tableName, groupName)) return
          // 決済手段による出しわけ
          if (!this.checkPaymentTerms(item.tableName, groupName)) return

          const group = _.find(table.groups, { variableName: groupName })
          // console.log(groupName, group, !group)
          let groupHide // group.childrenの項目が全てhide = trueの場合は情報群ごと非表示
          if (group && group.children) groupHide = _.findIndex(group.children || [], ['hide', false])
          if (!group || groupHide === -1) return
          // Types of Workのフィルタリング
          if (this.checkTypeOfWorks(group)) {
            // 図形の下に表示する、文字列を追加（該当の情報をレスポンスから抜き出す）
            let res, child, key, index
            if (this.timeLine) {
              // console.log(this.s, item, item.tableName, item.targetGroup)
              // NOTE: TimeLineタブは、特定の項目を表示する必要があるため、nodes.jsonにtargetGroupとtargetKeyを設定して対応する
              // res = this.isEditMode ? _.get(this.s.cloneItems, `${item.tableName}.${item.targetGroup}`) : _.get(this.s.res, `${item.tableName}.${item.targetGroup}`, {})
              res = _.get(this.s.res, `${item.tableName}.${item.targetGroup}`) || {}
              key = item.targetKey || undefined
              const targetGroup = _.find(table.groups, { variableName: item.targetGroup })
              child = targetGroup ? _.find(targetGroup.children, ['key', key]) : undefined
            } else {
              // NOTE: テーブル定義書で定義されている情報群の、非表示になっているものを除いて1番上の項目を表示する（または、Idがkey名に含まれている場合は次の項目を表示）
              // そのため、スキーマで定義されている情報群の項目の非表示に依存する（hide項目）
              // res = this.isEditMode ? _.get(this.s.cloneItems, `${item.tableName}.${groupName}`) : _.get(this.s.res, `${item.tableName}.${groupName}`, {})
              res = _.get(this.s.res, `${item.tableName}.${groupName}`)
              index = _.findIndex(group.children, ['hide', false])
              child = group.children[index]
              if (child && !child.key.includes('Id')) key = child.key
              else if (child && child.key.includes('Id') && group.children[index + 1]) key = group.children[index + 1].key
              else key = undefined
            }

            // console.log(index, key, child)
            // console.log(group, group.children[index], key)
            // console.log(res, group.variableName, res[key])
            if (res && (res[key] || res[key] === 0)) {
              // インプットタイプごとに追加する値を変換
              if (child.code && (child.inputType === 'radio' || child.inputType === 'select')) {
                // radio, select 区分値が必要なもの
                item.title = this.getDetail(child.code, res[key]) || undefined
              } else if (child.inputType === 'date') {
                // date 日付変換が必要なもの
                item.title = this.$options.filters.utcOffsetDateFormat(res[key]) || undefined
              } else if (child.inputType === 'dateTime') {
                // dateTime 時刻変換が必要なもの
                item.title = this.$options.filters.utcOffsetDateTimeFormat(res[key]) || undefined
              } else item.title = res[key] || undefined
            } else item.title = undefined
            // console.log(item.title)

            filtered.push(item) // nodeを格納
          }
        }
      })

      let items = [] // 最終的にreturnする配列
      // データセット変換処理
      items = filtered.map((item) => {
        if (item.type) {
          item.ctxRenderer = ({ ctx, id, x, y, state: { selected, hover }, style }) => {
            // canvasに表示するNameの文字数整形 各nodeの下
            const name = item.title && item.title.length > this.nameLength ? `${item.title.slice(0, this.nameLength)}...` : item.title || ''
            const times = (item.id === 'airGrp' || item.id === 'logisticsViaGrp') ? this.getLastSerialIndex(item.tableName, item.id) : 0; // 繰り返しの数を代入
            // 図形描画の設定 polygon: 多角形, arc: 円形
            if (item.type === 'polygon') {
              return {
                drawNode: drawPolygon({ ctx, id, x, y, state: { selected, hover }, style }, item.label || '', name),
                nodeDimensions: { width: 114, height: 60 } // size固定
              }
            } else {
              return {
                drawNode: drawArc({ ctx, id, x, y, state: { selected, hover }, style }, item.label || '', name, item.type, times),
                nodeDimensions: { width: 80, height: 80 } // size固定
              }
            }
          }
        }
        return item
      })

      if (!this.timeLine) {
        // Legend追加
        items.push({ id: 'legend', label: '', shape: 'image', image: '/images/legend.svg', x: 380, y: 460, size: 50, fixed: true })
        // 位置調整用の空ノード追加
        items.push({ id: 'empty1', shape: 'dot', size: 0, x: 500, y: 0 })
        items.push({ id: 'empty2', shape: 'dot', size: 0, x: -500, y: 0 })
      }

      return items
    },

    /**
    * canvasをクリックした際の処理
    * 親にクリックしたgroup情報を渡します
    */
    click(params) {
      if (this.exportMode) return
      if (params.nodes.length === 1 && params.nodes[0] !== 'legend' && params.nodes[0] !== 'finance') { // legendとfinanceはクリック対象外
        const nodeId = params.nodes[0]
        // console.log(nodeId + 'がクリックされました')
        const nodeItem = _.find(this.nodes, { id: nodeId })
        const table = _.find(this.tables, { variableName: nodeItem.tableName })
        const groupName = nodeItem.id.includes('customsAgentGrp') || nodeItem.id.includes('surveyorGrp') ? nodeItem.id.slice(0, -2) : nodeItem.id
        const group = _.find(table.groups, { variableName: groupName })
        this.$emit('show-detail', table.variableName, group )
        // 自動フォーカス
        // const network = this.$refs.network
        // network.focus(nodeId, this.focusOption)
      } else this.$emit('show-detail', '')
    },

    // 区分値から表示名を取得します NOTE: 随時追加
    getDetail(key, val) {
      if (key === 'TYPE_OF_SERVICE') return _.get(_.find(TYPE_OF_SERVICE_VARIABLES, {code: val}), 'label') || ''
      else if (key === 'TYPE_OF_SERVICE_FCL_LCL') return _.get(_.find(TYPE_OF_SERVICE_FCL_LCL_VARIABLES, {code: val}), 'label') || ''
      else return val || undefined
    },

    // 輸送手段による出し分け
    checkTransportation(tableName, groupName) {
      // console.log(tableName, groupName)
      const transportation = _.get(this.s, 'res.linkageTransportation.transferTermsGrp.transportation')
      if (!(transportation || transportation === 0)) return true
      const dictionaries = transportationControl;
      const target = _.find(dictionaries, (item) => {
        return item.variableName === `${tableName}.${groupName}`
      })
      if (!target) return true
      // console.log(groupName, target.value, transportation)
      if (target.value.includes(transportation)) return true
      else return false
    },

    // 決済条件による出しわけ
    checkPaymentTerms(tableName, groupName) {
      const paymentId = _.get(this.s, 'res.linkageContract.paymentTermsGrp.paymentId')
      if (!(paymentId || paymentId === 0)) return true
      const target = _.find(paymentControlGraphicalRelationMap, (item) => {
        return item.variableName === `${tableName}.${groupName}`
      })
      if (!target) return true
      if (target.value.includes(paymentId)) return true
      else return false
    },

    // Air, Viaの繰り返し項目数を取得します
    getLastSerialIndex(tableName, groupName) {
      const targetGroup = _.get(this.s, `res.${tableName}.${groupName}`)
      if (!targetGroup) return 0
      const objKeys = Object.keys(targetGroup)
      const objValues = _.reject(Object.values(targetGroup), o => _.isArray(o))
      const lastIndex = _.findLastIndex(objValues, (item) => {
        return item || item === 0
      })
      const keyNumber = lastIndex === -1 ? 0 : objKeys[lastIndex].replace(/[^0-9]/g, '')
      // console.log(targetGroup, objKeys, objValues, lastIndex, keyNumber)
      if (keyNumber) return Number(keyNumber)
      else return 0

    },

    /**
    * 開発用: nodeの座標を変更したjsonをエクスポートします
    * 手順：
    * 1. data内の、exportMode: true
    * 2. canvas内のノード位置を調整
    * 3. exportボタンを押して、生成されたjsonを全選択コピー、↓のjsonに全選択ペーストして上書き
    * src/dictionaries/graphical/relationMap/nodes.json
    * 4. data内の、exportMode: false ←←←忘れないこと
    */
    addConnections(elem, index) {
      elem.connections = this.$refs.network.getConnectedNodes(index)
    },
    clearOutputArea() {
     this.$refs.exportArea.value = ""
    },
    objectToArray(obj) {
      return Object.keys(obj).map(function (key) {
        obj[key].id = key
        return obj[key]
      })
    },
    resizeExportArea() {
      this.$refs.exportArea.style.height = 1 + this.$refs.exportArea.scrollHeight + "px"
    },
    exportNetwork() {
      this.clearOutputArea()
      let nodes = this.objectToArray(this.$refs.network.getPositions())
      nodes.forEach(this.addConnections)
      const exportValue = {
        nodes: this.nodes.map((item) => {
          const node = _.find(nodes, { id: item.id })
          console.log(node)
          const newNode = this.timeLine ? {
            id: item.id,
            label: item.label,
            tableName: item.tableName,
            x: node.x,
            y: node.y,
            type: item.type,
            targetGroup: item.targetGroup,
            targetKey: item.targetKey
          } : {
            id: item.id,
            label: item.label,
            tableName: item.tableName,
            x: node.x,
            y: node.y,
            type: item.type
          }
          return newNode
        })
      }
      console.log(exportValue)
      this.$refs.exportArea.value = JSON.stringify(exportValue, undefined, 2)
      this.resizeExportArea()
    },
  }
}
</script>
