/**
 * 'vue-visjs'、Networkコンポーネントに渡すnodeのカスタム図形描画関数
 * Ph2段階では、TwGraphicalNetworkItem.vueでのみ利用
 */

/**
 * 六角形を描画します
 */
// eslint-disable-next-line no-unused-vars
export const drawPolygon = ({ ctx, id, x, y, state: { selected, hover }, style }, title1, title2) => {
  const scale = selected || hover ? 1.075 : 1;
  ctx.fillStyle = 'white'; // 塗り設定
  // 線の設定
  let linearGradient = ctx.createLinearGradient(x - 20, y, x + 30, y + 10);
  linearGradient.addColorStop(0, '#0085c5');
  linearGradient.addColorStop(1.0, '#00c1ff');
  if (selected) {
    ctx.lineWidth = 5;
  } else {
    ctx.lineWidth = 3;
  }
  ctx.strokeStyle = linearGradient;
  // 図形を描画
  ctx.beginPath();
  const r = style.size;
  const w = 84.58 * scale;
  const h = 60 * scale;
  const ow = 14.9 * scale;
  const ry = (r + 10) * scale;
  ctx.moveTo(x, y + ry);
  ctx.lineTo(x - w / 2, y + ry);
  ctx.lineTo(x - w / 2 - ow, y + ry - h / 2);
  ctx.lineTo(x - w / 2, y + ry - h);
  ctx.lineTo(x, y + ry - h);
  ctx.lineTo(x + w / 2, y + ry - h);
  ctx.lineTo(x + w / 2 + ow, y + ry - h / 2);
  ctx.lineTo(x + w / 2, y + ry);
  ctx.closePath();
  ctx.fill(); // 塗り
  ctx.stroke(); // 線
  // 文字設定
  ctx.font = "400 12px 'Noto Sans', 'Noto Sans JP', sans-serif";
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillStyle = '#3E3A39';
  // 文字描画
  ctx.fillText(title2, x, y + 44);
  ctx.font = "bold 16px 'Noto Sans', 'Noto Sans JP', sans-serif";
  ctx.fillText(title1, x, y);
};

/**
 * 円形を描画します
 */
// eslint-disable-next-line no-unused-vars
export const drawArc = ({ ctx, id, x, y, state: { selected, hover }, style }, title1, title2, type, times) => {
  const scale = selected || hover ? 1.075 : 1;
  let fillStyle; // 塗り設定
  let strokeStyle; // 線の設定
  if (type === 'red') {
    // タイプred ----------------------------------------
    fillStyle = '#FFE3E3';
    strokeStyle = '#F7C0C0';
  } else if (type === 'green') {
    // タイプgreen ----------------------------------------
    fillStyle = '#D8F6EA';
    strokeStyle = '#C6EADA';
  } else if (type === 'purple') {
    // タイプpurple ----------------------------------------
    fillStyle = '#EFE4FF';
    strokeStyle = '#9F6BE2';
  } else if (type === 'yellow') {
    // タイプYellow ----------------------------------------
    fillStyle = '#FFF5C2';
    strokeStyle = '#F4E282';
  } else {
    // タイプBlue ----------------------------------------
    fillStyle = '#DFEEFF';
    strokeStyle = '#9FC1E9';
  }

  // 塗り、線
  ctx.fillStyle = fillStyle;
  ctx.lineWidth = 3;
  if (selected) {
    ctx.strokeStyle = strokeStyle;
  } else {
    ctx.strokeStyle = 'transparent';
  }
  // 影の設定
  ctx.shadowColor = 'rgba(170, 170, 204, 0.5)'; // 影の色
  ctx.shadowOffsetX = 0; // x 方向
  ctx.shadowOffsetY = 8; // y 方向
  ctx.shadowBlur = 12 ; // ぼかし
  // 図形を描画
  ctx.beginPath();
  ctx.arc(x, y, 40 * scale, 0, 2 * Math.PI);
  ctx.closePath();
  ctx.fill(); // 塗り
  ctx.shadowColor = 'transparent';
  ctx.stroke(); // 線

  // 文字設定
  ctx.font = "400 12px 'Noto Sans', 'Noto Sans JP', sans-serif";
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillStyle = '#3E3A39';
  // 文字を描画
  ctx.fillText(title2, x, y + 52);
  ctx.font = "bold 12px 'Noto Sans', 'Noto Sans JP', sans-serif";
  // 半角スペースを改行
  const lineHeight = 14;
  for (var lines = title1.split(' '), i = 0, l = lines.length; i < l; i++) {
    var line = lines[i];
    var addY = -(l - 1) * lineHeight * 0.5;
    // 2行目以降の水平位置は行数とlineHeightを考慮する
    if (i) {
      addY += lineHeight * i;
    }
    ctx.fillText(line, x, y + addY);
  }

  // バッジ
  if (times) {
    // 円を描画
    ctx.beginPath();
    ctx.fillStyle = '#F47B28';
    ctx.arc(x + 30, y - 30, 11, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill(); // 塗り
    // 文字を描画
    ctx.font = "bold 12px 'Noto Sans', 'Noto Sans JP', sans-serif";
    ctx.fillStyle = '#FFFFFF';
    ctx.fillText(String(times), x + 30, y - 29);
  }
};
